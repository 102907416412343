import React, {useRef} from 'react';
import {Link, useParams} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../../context"
import Loading              from         '../MainLayout/Loading/Loading';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import RichTextResponse from "../../contentful-render-options"

import "./singleBeitrag.css"



export default function SingleBeitragComponent({begegnung, datum, fotos, kategorie, text, titel, wettbewerb, id, instagram, links, autor}) {
     
    text = documentToHtmlString(text);
    datum = new Date(datum);

    const {slug} = useParams();

    
    let titleTag;
    if (begegnung){
        titleTag = begegnung;
    } else {
        titleTag = titel;
    }

    return (
            <div className="single-beitrag text-wrapper">

            <Helmet>
                <title>{titleTag}</title>
                <meta name="description" content={titel}/>
            </Helmet>

                <h1 className="titel">{titel}</h1>
                <div className="text" dangerouslySetInnerHTML={{__html: text}} ></div>

                <br/>

                {/* {instagram&&<div className="text" dangerouslySetInnerHTML={{__html: instagram}} ></div>}

                <br/> */}
                
                
                <h5 className="datum">{datum.getDate()}. {datum.getMonth()+1}. {datum.getFullYear()}</h5>
                <h4 className="autor">{autor}</h4>
                
                <div className="inner-text-wrapper single-beitrag">
                    <div className="beitrag-info single">
                            {begegnung&&
                                <div className="beitrag-info__inner">
                                    <div className="beitrag-info__inner_ü">Begegnung: </div>
                                    <div>{` ${begegnung}`}</div>
                                </div>  }

                            {wettbewerb&&
                            <div className="beitrag-info__inner">
                                <div className="beitrag-info__inner_ü">Wettbewerb: </div>
                                <div>{` ${wettbewerb}`}</div>
                            </div>  }

                            {kategorie&&
                            <div className="beitrag-info__inner">
                                <div className="beitrag-info__inner_ü">Kategorie: </div>
                                <div>{` ${kategorie}`}</div>
                            </div>  }
                    </div>
                    <Link to={`/beiträge`}>
                        <button className="btn btn-primary">alle Beiträge</button>
                    </Link>
                </div>
                
            </div>
    )
}
