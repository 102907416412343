import React, {useEffect, useLayoutEffect} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { createBrowserHistory } from 'history';

import './components/Layout/index.css';

import Error from "./pages/Error"
import Home from './pages/Home'
import AlteHerren from './pages/AlteHerren'
import Herren from './pages/Herren'
import JFG from './pages/JFG-Spessarttor'
import U11 from './pages/U11'
import U9 from './pages/U9'
import U7 from './pages/U7'
import U5 from './pages/U5'
import Vorstandschaft from './pages/Vorstandschaft'
import Beiträge from './pages/Beiträge'
import SingleBeitrag from './pages/SingleBeitrag'
import Chronik from './pages/Chronik'
import Downloads from './pages/Downloads'
import StammvereineUndSportgelände from './pages/StammvereineUndSportgelände';
import ImpressumPage from './pages/Impressum'
import Datenschutz from './pages/Datenschutz'
import Kontakt from './pages/Kontakt'
import Fanshop from './pages/Fanshop'

// Google Tag Manager
// const tagManagerArgs = {gtmId: 'GTM-KVS7CQW'}
// TagManager.initialize(tagManagerArgs)


const history = createBrowserHistory();

function App() {

  useEffect(() => {
    ReactGA.initialize('UA-203665661-1');
    ReactGA.pageview(window.location.pathname);
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    window.dataLayer.push({
      event: 'pageview'
    });
  }, [])

  return (
    <HelmetProvider>
      {/* history={history} */}
    <Router history={history}>
      <Helmet>
        <title>Die Offizielle Webseite des SV Sendelbach-Steinbach</title>
        <meta name="description" content="Willkommen auf der offiziellen Vereins-Website des SV-Sendelbach-Steinbach. Hier werdet ihr mit allen Informationen zu unserem Fußballverein versorgt."/>
      </Helmet>
      <Switch>
        <Route exact path="/">
            <Home/>
        </Route>
        <Route path="/alte-herren">
            <AlteHerren/>
        </Route>
        <Route path="/herren">
            <Herren/>
        </Route>
        <Route path="/jfg-spessarttor">
            <JFG/>
        </Route>
        <Route path="/u11">
            <U11/>
        </Route>
        <Route path="/u9">
            <U9/>
        </Route>
        <Route path="/u7">
            <U7/>
        </Route>
        <Route path="/u5">
            <U5/>
        </Route>
        <Route path="/Vorstandschaft">
            <Vorstandschaft/>
        </Route>

        <Route  exact path="/beiträge">
          <Beiträge />
        </Route>
        <Route  path="/beiträge/:slug">
          <SingleBeitrag />
        </Route>
        <Route  path="/fanshop">
          <Fanshop />
        </Route>
        <Route  path="/chronik">
          <Chronik />
        </Route>
        <Route  path="/downloads">
          <Downloads />
        </Route>
        <Route  path="/stammvereine-sportgelände">
          <StammvereineUndSportgelände />
        </Route>

        <Route  path="/impressum">
          <ImpressumPage />
        </Route>
        <Route  path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route  path="/kontakt">
          <Kontakt />
        </Route>
        <Route  path="*">
          <Error />
        </Route>
      </Switch>
    </Router>
    </HelmetProvider>
  );
}

export default App;
