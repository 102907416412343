import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import KontaktListe         from "../components/Kontakt/KontaktListe"
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';


export default function Kontakt() {
    
    const {images, filterFotos} = useGlobalContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Alle Kontaktdaten des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Hier befinden sich alle Kontaktdaten, die man braucht, wenn man den SV Sendelbach-Steinbach kontaktieren möchte"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Kontakt" />
                :<Loading />
            }
            <KontaktListe />
            <Footer />
        </div>
    )
}
