import React, {useState, useEffect} from 'react'
import Überschrift from '../MainLayout/Überschrift/Überschrift'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import "./verein.css"

export default function DownloadButton({logo, sportgelaende, text, verein, website, ort}) {

    const [count, setCount] = useState(0);
    const [images, setImages] = useState(sportgelaende);
    const [sliderOn, sliderOnSwitch] = useState(true);


    useEffect(() => {
        const lastIndex = images.length -1;
        if(count < 0)           { setCount(lastIndex)}
        if(count > lastIndex)   { setCount(0)}
    }, [count, images]);
  
    
    useEffect(() => {
        if(images.length > 1 & sliderOn){
            let slider = setInterval(() => {setCount(count+1)}, 4000)
            return () => clearInterval(slider)
        }
    }, [count, sliderOn])
  
    const checkActive = (number) => {
      if(number === count){ return "activeSlide"}
      if(count === 0 & number === images.length -1){ return "lastSlide"}
      if(number === count-1 & number >= 0){ return "lastSlide"}
      else{ return "nextSlide"}
    }

  

    return (
        <div className="verein" >

            <Überschrift überschrift={verein} />

            <div className="verein-grid">
                <div className="logo">
                    <img src={logo} alt="logo"/>
                </div>
                <h3 className="text">{text}</h3>
                <a href={`http://${website}`} target="blank">
                    <h3 className="website btn-1">{website}</h3>
                </a> 
            </div>

            <div className="verein-wrapper">
                <div className="verein-wrapper-inner">
                    <iframe className="maps" src={ort} loading="lazy"></iframe>
                </div>

                {/* <div className="verein-wrapper-inner" style={{background: "grey"}}>
                    <div>Google Maps</div>
                </div> */}
                <div className="verein-wrapper-inner" onClick={()=>sliderOnSwitch(state => !state)}>
                    {
                        images.map ( (image, index) => {  
                            return (
                                <div key={index} className={`slider-wrapper ${checkActive(index)}`}>
                                    <img className="slider-image" src={image} alt={image.title} description={image.description}/>
                                </div>
                            )
                        })   
                    }
                    <button className="prev" onClick={() => images.length>1&&setCount(count-1)}><FiChevronLeft /></button>
                    <button className="next" onClick={() => images.length>1&&setCount(count+1)}><FiChevronRight /></button>
                </div>
            </div>

        </div>
    )
}










