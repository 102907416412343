import React from 'react'
import "./impressum.css"

export default function Impressum() {

    return (
        <div className="impressum-wrapper">
            <h4>Anschrift</h4>
            <p>SV Sendelbach-Steinbach e.V.</p>
            <p>Olaf Kunze</p>
            <p>Gellenäckerweg 2</p>
            <p>97816 Lohr</p>
            <br/><br/>

            <h4>E-Mail</h4>
            <p>markus.gutthaeter@sv-sendelbach-steinbach.de</p>
            <br/><br/>

            <h4>Internet</h4>
            <p>www.sv-sendelbach-steinbach.de</p>
            <br/><br/>

            <h4>Vorsitzende</h4>
            <p>Olaf Kunze (SV Sendelbach)</p>
            <p>Markus Gutthäter (SV Steinbach)</p>
            <br/><br/>

            <h4>Web-Implementierung</h4>
            <p>Alexander Nadler</p>
            <a href="https://www.alina-webseiten.de/">www.alina-webseiten.de</a>
            
        </div>
    )
}