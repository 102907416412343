import React, {useEffect, useState} from 'react';
import {useGlobalContext}    from "../../context"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import "./beiträgeListe.css"


import {BiDownArrow} from "react-icons/bi"

import Loading              from         '../MainLayout/Loading/Loading';
import Beitrag from "./Beitrag"
import MehrLaden from "../MainLayout/MehrLaden/MehrLaden"
import Suche from "./Suche"


const Count = () => {
    if(window.sessionStorage.getItem("BeiträgeCount")){
        return parseInt(window.sessionStorage.getItem("BeiträgeCount"));
    } else {
        return 6;
    }
}

// const WindowScroll = () => {
//     if(window.sessionStorage.getItem("WindowScroll")){
//         return window.sessionStorage.getItem("WindowScroll");
//     } else {
//         return 0;
//     }
// }

export default function BeiträgeListe() {
    
    const [count, setCount] = useState(Count);
    const {beiträge, filterFotos} = useGlobalContext();
    const [filteredBeiträge, setFilteredBeiträge] = useState(beiträge);
    // const [windowScroll, setWindowScroll] = useState(WindowScroll);

    useEffect(() => {
        setFilteredBeiträge(beiträge)
        window.sessionStorage.setItem("BeiträgeCount", count)
    }, [beiträge,count]); 

    // window.onscroll = () => {
    // }

    function suchen(e){
        let f = beiträge.filter((beitrag, index) => {
            let {begegnung, datum, kategorie, text, titel, wettbewerb, id} = beitrag;

            text = text.content.map(item => {
                return item.content[0].value
            })

            if(begegnung){
                if(begegnung.toUpperCase().includes(e.toUpperCase())){
                    return true;
                }
            }
            if(datum){
                if(datum.toUpperCase().includes(e.toUpperCase())){
                    return true;
                }
            }
            if(kategorie){
                if(kategorie.toUpperCase().includes(e.toUpperCase())){
                    return true;
                }
            }
            if(text){
                let check;
                text.map((item, index)=>{
                    if(typeof item === "string"){
                        if(item.toUpperCase().includes(e.toUpperCase())){
                            check = true;
                        }
                    }
                })
                if(check){return true}
            }
            if(titel){
                if(titel.toUpperCase().includes(e.toUpperCase())){
                    return true;
                }
            }
            if(wettbewerb){
                if(wettbewerb.toUpperCase().includes(e.toUpperCase())){
                    return true;
                }
            }

            return false;
        });

        setFilteredBeiträge(f);
    }

    return(
        <div className="beiträge"> 
            {filteredBeiträge&&
                <Suche suchen={suchen}/>
            }

            
                {filteredBeiträge
                    ?
                    <div className="beiträge-wrapper">
                            {filteredBeiträge.map((beitrag, index) => {
                                if(index < count){
                                    return(
                                        <Beitrag key={beitrag.id} {...beitrag} />
                                    )
                                }
                            })
                        }
                    </div>
                    :
                    <div className="überschrift">
                        keine entsprechenden Beiträge vorhanden...
                    </div>
                }
            
            {
                filteredBeiträge && count < filteredBeiträge.length
                &&
                <div className="mehr-laden" onClick={()=>setCount(count => count + 3)}>
                    <p className="überschrift">mehr laden</p>
                    <BiDownArrow className="icon" />
                </div>
            }
            
            
        </div>
    )
  
}
