import React, {useEffect, useState} from 'react';

import Loading from "../MainLayout/Loading/Loading"
import PlayerCard from "./PlayerCard.js"
import Überschrift from "../MainLayout/Überschrift/Überschrift"

import contentful from "../../Contentful"



export default function PlayerCardsList() {

    const [spielerListe, setSpielerListe]   = useState([]);
    const [loading, setLoading]             = useState([true])

    useEffect(() => {
        setLoading(true)
        getSpielerListe();
        setLoading(false)
    }, [])

    function formatSpieler(items){
        let tempSpieler = items.map(item => {
            let id = item.sys.id;
            let foto = item.fields.foto.fields.file.url;

            let spieler = {...item.fields, id, foto}
            return spieler
        })
        return tempSpieler;
    }

    async function getSpielerListe() {
        try {
            let response = await contentful.getEntries({
                content_type: "spielerListe",
                order: "fields.nachname"
            })
            const formatedSpieler = formatSpieler(response.items);
            setSpielerListe(formatedSpieler)
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Überschrift überschrift="Trainer" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {spielerListe.map((person) => {
                        if(person.isTrainer){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Kader" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {spielerListe.map((person) => {
                        if(person.funktion === "Spieler"){
                            return <PlayerCard {...person} key={person.id} />
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Unterstützer" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {spielerListe.map((person) => {
                        if(person.funktion != "Spieler" && person.isTrainer === false){
                            const funktion = person.position
                            const position = null
                            person = {...person, funktion, position}
                            return <PlayerCard {...person} key={person.id} />
                        }
                    })}
                </div>
            }
        </div>
    )
}
