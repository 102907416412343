import React, {useEffect}                            from 'react'
import {Link, useParams}                from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"
import Navbar                           from "../components/MainLayout/Navbar/Navbar";
import Überschrift                      from '../components/MainLayout/Überschrift/Überschrift';
import SingleBeitragComponent           from "../components/Beiträge/SingleBeitragComponent";
import Footer                           from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';
import Error from "./Error"

export default function SingleBeitrag() {

    const {images, filterFotos, beiträge} = useGlobalContext()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const {slug} = useParams();

    if(beiträge && images){
        var singleBeitragArray = beiträge.filter((beitrag) => {
            let convertedTitel = beitrag.titel.replace(/\s+/g, '-').replace("/", '-').toLowerCase();
            return convertedTitel === slug;
        });

        if(singleBeitragArray[0]){
            var singleBeitrag = singleBeitragArray[0];
            var singleBeitragDatum = new Date(singleBeitrag.datum);
        }
    }


    //falls keine Fotos beim Beitrag dabei sind, nutze Standard-Fotos
    if(beiträge && images && singleBeitrag){
        let newKategorie = singleBeitrag.kategorie;

        
        //Ersatzfoto für Kategorie 1. Mannschaft und 2. Mannschaft = Herren Fotos
        if(singleBeitrag.kategorie === "1. Mannschaft" || singleBeitrag.kategorie === "2. Mannschaft"){
            newKategorie = "Herren";
        }

        //Ersatzfoto für Kategorien "Weiteres, Alle, News"
        if(singleBeitrag.kategorie === "Alle" || singleBeitrag.kategorie === "Alte Herren" || singleBeitrag.kategorie === "News"){
            newKategorie = "Ersatzfoto";
        }
        
        //Fotos zurück geben
        if(filterFotos(newKategorie, images)){
            var filteredFotos = filterFotos(newKategorie, images).fotos;
        }

        //falls Kategorie != keine entsprechenden Fotos vorhanden
        if(!filteredFotos){
            filteredFotos = filterFotos("Startseite", images).fotos;
        }

    }


    return (
        <div>
            <Helmet>
                <title>Einzelner Beitrag, Spielbericht, Ankündigung oder Nachricht Rund um den SV Sendelbach-Steinbach</title>
                <meta name="description" content="Einzelner Beitrag, Spielbericht, Ankündigung oder Nachricht Rund um den SV Sendelbach-Steinbach"/>
            </Helmet>

            {images, beiträge, singleBeitrag
                ?<Navbar src={singleBeitrag.fotos?singleBeitrag.fotos:filteredFotos}
                         pageName={`Beitrag - ${singleBeitragDatum.getDate()}. ${singleBeitragDatum.getMonth()+1}. ${singleBeitragDatum.getFullYear()}`} 
                  />
                :<Error />
            }
            {beiträge, singleBeitrag
                ?<SingleBeitragComponent {...singleBeitrag}/>
                :<div/>
            }
            <Footer />
        </div>
    )
}