import React, {useState, useEffect} from 'react'
import Überschrift from '../Überschrift/Überschrift'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import {useGlobalContext}    from "../../../context"
import "./archivSlider.css"
import Loading from '../Loading/Loading';


export default function ArchivSlider({archivName}) {

    const [count, setCount] = useState(0);
    const {archiv, filterArchiv} = useGlobalContext();
    const [sliderOn, sliderOnSwitch] = useState(true);

    let images;

        if(archiv){
            let archivNeu = filterArchiv(archivName, archiv);
            images = archivNeu.fotos;
        }
    
        useEffect(() => {
            const lastIndex = images.length -1;
            if(count < 0)           { setCount(lastIndex)}
            if(count > lastIndex)   { setCount(0)}
        }, [count, images]);
    
        
        useEffect(() => {
        if(images.length > 1 & sliderOn){
            let slider = setInterval(() => {setCount(count+1)}, 4000)
            return () => clearInterval(slider)
        }
        }, [count, sliderOn])
    
        const checkActive = (number) => {
        if(number === count){ return "activeSlide"}
        if(count === 0 & number === images.length -1){ return "lastSlide"}
        if(number === count-1 & number >= 0){ return "lastSlide"}
        else{ return "nextSlide"}
        }
    

    return (
        <div className="archiv" >
                <div className="archiv-wrapper" onClick={()=>sliderOnSwitch(state => !state)}>
                    {
                        archiv
                            ?images.map ( (image, index) => {  
                                return (
                                    <div key={index} className={`slider-wrapper ${checkActive(index)}`} >
                                        <img className="archiv-slider-image" src={image.src}  />
                                        {/* <img className="slider-image-background" src={image.src} style={{background: `center / cover no-repeat url(${image.src})`}}/> */}
                                    </div>

                                )
                            })
                            :<Loading/> 
                    }
                    <button className="prev" onClick={() => images.length>1&&setCount(count-1)}><FiChevronLeft /></button>
                    <button className="next" onClick={() => images.length>1&&setCount(count+1)}><FiChevronRight /></button>
                </div>
                <div className="archiv-text">
                    {
                        archiv
                            ?images.map ( (image, index) => {  
                                return (
                                    <div key={index} className={`${checkActive(index)}`}>
                                        <h6 className="slider-titel">{image.title}</h6>
                                        <div className="slider-description">{image.description}</div>
                                    </div>

                                )
                            })
                            :<Loading/> 
                    }
                </div>
        </div>
    )
}










