import React from 'react'
import "./Überschrift.css"

function Überschrift({überschrift}) {

    return (
        <h2 className="überschrift-wrapper">
            <div className="überschrift__text">
                {überschrift}
            </div>    
        </h2>
    )
}

export default Überschrift
