import React, {useEffect}                from 'react'
import {useGlobalContext}    from "../context"
import {Helmet} from "react-helmet-async"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Chronik          from "../components/Chronik/Chronik";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';


export default function ChronikPage() {
    
    
    const {images, filterFotos} = useGlobalContext();

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Die Chronik des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Hier gibt es alle wichtigen Ereignisse der letzten Jahre Rund um den SVSS"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName={`Chronik`} />
                :<Loading />
            }
            <Chronik />
            <Footer />
        </div>
    )
}