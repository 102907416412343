import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';
import Impressum            from "../components/Impressum/Impressum"


export default function ImpressumPage() {

    
    const {images, filterFotos} = useGlobalContext();

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Das Impressum der Homepage des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Das Impressum der Homepage des SV Sendelbach-Steinbach"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Impressum" />
                :<Loading />
            }
            <Impressum />
            <Footer />
        </div>
    )
}
