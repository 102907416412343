import React, {useEffect, useState} from 'react';
import "./chronik.css"

import Loading from "../MainLayout/Loading/Loading"
import Überschrift from "../MainLayout/Überschrift/Überschrift"
import ChronikElement from "./ChronikElement"

import contentful from "../../Contentful"


export default function Chronik() {

    const [chronikListe, setChronikListe]   = useState();
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        getChronik();
    }, [])

    function formatChronik(items){
        let tempChronik = items.map(item => {
            let id = item.sys.id;
            let foto = null;
            if(item.fields.foto){
                foto = item.fields.foto.fields.file.url;
            }
            let link;
            if(item.fields.link){
                link = item.fields.link.sys.id;
            }

            let chronik = {...item.fields, id, foto, link}
            return chronik
        })
        return tempChronik;
    }

    async function getChronik() {
        setLoading(true)
        try {
            let response = await contentful.getEntries({
                content_type: "chronik",
                order: "-fields.datum"
            })
            const formatedChronik = formatChronik(response.items);
            console.log(response.items)
            console.log(formatedChronik)
            setChronikListe(formatedChronik)
            setLoading(false)          
        } catch (error) {
            console.log(error)
        }
    }


    return(
        <ul className="timeline">
            {loading
                ?<Loading />
                :chronikListe.map((chronikElement) => {
                            return (<ChronikElement {...chronikElement} key={chronikElement.id} />)
                })
            }
        </ul>
    )
}