import {createClient} from "contentful"

export default createClient({
    space: "6twc7ro3w5e8",
    accessToken: "_MsfA-SAmH-LDUQBvJEJn1A8TUv2lRDCJImxg7lnqAw",
});

// export default createClient({
//     space: process.env.REACT_APP_CONTENTFUL_API_SPACE,
//     accessToken: process.env.REACT_APP_CONTENTFUL_API_ACCESS_TOKEN,
// });