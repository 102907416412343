import React, {useEffect, useState} from 'react';

import "./downloadList.css"
import Loading from "../MainLayout/Loading/Loading"
import DownloadButton from "./DownloadButton"
import Überschrift from "../MainLayout/Überschrift/Überschrift"

import contentful from "../../Contentful"



export default function DownloadList() {

    const [downloads, setDownloads]   = useState([]);
    const [loading, setLoading]       = useState([true])

    useEffect(() => {
        setLoading(true)
        getDownloads();
        setLoading(false)
    }, [])

    function formatDownloads(items){
        let tempDownloads = items.map(item => {
            let id = item.sys.id;
            let datei = item.fields.datei.fields.file.url;

            let downloads = {...item.fields, id, datei}
            return downloads
        })
        return tempDownloads;
    }

    async function getDownloads() {
        try {
            let response = await contentful.getEntries({
                content_type: "downloads",
                order: "-fields.datum"
            })
            const formatedDownloads = formatDownloads(response.items);
            setDownloads(formatedDownloads)
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Überschrift überschrift="Beitrittserklärungen" />
            {loading
                ?<Loading></Loading>
                :
                <div className="content-wrapper">
                    {downloads.map((download) => {
                        if(download.typ === "Beitrittserklärung"){
                            return (<DownloadButton {...download} key={download.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Jahreshauptversammlungen" />
            {loading
                ?<Loading></Loading>
                :
                <div className="content-wrapper">
                    {downloads.map((download) => {
                        if(download.typ === "Jahreshauptversammlung"){
                            return <DownloadButton {...download} key={download.id} />
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Ecken" />
            {loading
                ?<Loading></Loading>
                :
                <div className="content-wrapper">
                    {downloads.map((download) => {
                        if(download.typ === "Ecke"){
                            return <DownloadButton {...download} key={download.id} />
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Weiteres" />
            {loading
                ?<Loading></Loading>
                :
                <div className="content-wrapper">
                    {downloads.map((download) => {
                        if(download.typ === "Weiteres"){
                            return <DownloadButton {...download} key={download.id} />
                        }
                    })}
                </div>
            }
        </div>
    )
}
