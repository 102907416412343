import React from 'react'
import "./trainingszeiten.css"

export default function Trainingszeiten({kommentare, team, training}) {

    return (
        <div className="training wrapper">
            <div className="termin wrapper">
                {training.map((training, index) => {
                    return(
                        <div key={index} className="termin__inner" >
                            <div className="tag">{training.tag}</div>
                            <div className="uhrzeit">{training.uhrzeit} Uhr</div>
                            {training.ort?<h4 className="ort">{training.ort}</h4>:<div></div>}
                        </div>
                    )
                })}
            </div>
            <div className="kommentar wrapper">
                {kommentare
                    ?kommentare.map((kommentar, index) => {
                        return(
                            <div key={index} className="kommentar__inner" >
                                <h5 className="">{kommentar.überschrift}</h5>
                                <div className="inhalt">: {kommentar.inhalt}</div>
                            </div>
                        )
                    })
                    : <div></div>
                }
            </div>
        </div>
    )
}