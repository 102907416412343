import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom"
import "./chronik.css"



export default function ChronikElement({titel, beschreibung, datum, foto , id, link}) {

    datum = new Date(datum);

    return(
        <div>
        {link
            ?
            <Link className="link" to={`beiträge/${link}`}>
                <li className="timeline-event">
                    <label className="timeline-event-icon"></label>
                    <div className="timeline-event-copy">
                        <p className="timeline-event-thumbnail überschrift">{datum.getFullYear()}</p>
                        <h3 className="chronik-titel">{titel}</h3>
                        <p>{beschreibung}</p>
                        {foto
                            &&
                            <div className="foto-wrapper">
                                <img src={foto} alt="foto"/>
                            </div>
                        }
                    </div>
                </li>
            </Link>
            :
            <li className="timeline-event">
                    <label className="timeline-event-icon"></label>
                    <div className="timeline-event-copy">
                        <p className="timeline-event-thumbnail überschrift">{datum.getFullYear()}</p>
                        <h3 className="chronik-titel">{titel}</h3>
                        <p>{beschreibung}</p>
                        {foto
                            &&
                            <div className="foto-wrapper">
                                <img src={foto} alt="foto"/>
                            </div>
                        }
                        
                        
                    </div>
            </li>
        }
        </div>
    )
}