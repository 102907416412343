import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './components/Layout/index.css';
import App from './App';
import {ContextProvider} from "./context";


ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);