import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Datenschutz          from "../components/Datenschutz/Datenschutz";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';


export default function Datschenschutz() {
    
    const {images, filterFotos} = useGlobalContext();

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Der Datenschutz der SV Sendelbach-Steinbach Homepage</title>
                <meta name="description" content="Der Datenschutz der SV Sendelbach-Steinbach Homepage."/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Datschenschutz" />
                :<Loading />
            }
            <Datenschutz />
            <Footer />
        </div>
    )
}
