import React from 'react'

import "./PlayerCard.css"

function PlayerCard({vorname, nachname, position, foto, geburtstag, beimSvssSeit, funktion, verein, email, telefonnummer}) {

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (          
        <div className="player-card">
            <div className="card">
                <div className="front" >
                    <img src={foto} alt=""/>
                </div>
                <div className="back">
                    <div>
                        <h3>{vorname} {nachname}</h3>
                        <p></p>
                        {funktion!="Spieler"&&
                            <span>
                                <h6>Funktion</h6>
                                <p className="">{funktion}</p>
                            </span>
                        }
                        {position&&
                            <span>
                                <h6>Position</h6>
                                <p className="">{position}</p>
                            </span>
                        }
                        {/* {beimSvssSeit&&
                            <span>
                                <h6>beim SVSS seit</h6>
                                <p className="">{beimSvssSeit}</p>
                            </span>
                        } */}
                        {geburtstag&&
                            <span>
                                <h6>Alter</h6>
                                <p className="">{getAge(geburtstag)}</p>
                            </span>
                        }
                        {verein&&
                            <span>
                                <h6>Verein</h6>
                                <p className="">{verein}</p>
                            </span>
                        }
                        {email&&
                            <span>
                                <h6>Email</h6>
                                <p className="">{email}</p>
                            </span>
                        }
                        {telefonnummer&&
                            <span>
                                <h6>Telefonnummer</h6>
                                <p className="">{telefonnummer}</p>
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlayerCard