import React from 'react';
import FanshopComponent from './Fanshop Component';

export default function Fanshop() {
    return (
        <div>
            <FanshopComponent />
        </div>
    )
}
