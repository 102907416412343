import React from 'react'
import "./datenschutz.css"

export default function Datenschutz() {

    return (
        <div className="datenschutz-wrapper">
            <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
            <p>Nur mit Ihrer ausdr&uuml;cklichen Einwilligung sind einige Vorg&auml;nge der Datenverarbeitung m&ouml;glich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
            <strong>Recht auf Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</strong>
            <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Versto&szlig;es ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu. Zust&auml;ndige Aufsichtsbeh&ouml;rde bez&uuml;glich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:&nbsp;<a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener norefferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
            <strong>Recht auf Daten&uuml;bertragbarkeit</strong>
            <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aush&auml;ndigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
            <strong>Recht auf Auskunft, Berichtigung, Sperrung, L&ouml;schung</strong>
            <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten. Diesbez&uuml;glich und auch zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit &uuml;ber die im Impressum aufgef&uuml;hrten Kontaktm&ouml;glichkeiten an uns wenden.</p>
            


            <p><strong>Google Analytics</strong><br />Soweit Sie Ihre Einwilligung erkl&auml;rt haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC. Zust&auml;ndiger Dienstanbieter in der EU ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (&bdquo;Google&ldquo;).</p>
            <p><strong>Umfang der Verarbeitung</strong><br />Google Analytics verwendet Cookies, die eine Analyse der Benutzung unserer Webseiten durch Sie erm&ouml;glichen. Die mittels der Cookies erhobenen Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert.</p>
            <p>Wir nutzen die Funktion &lsquo;anonymizeIP&rsquo; (sog. IP-Masking): Aufgrund der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
            <p>W&auml;hrend Ihres Website-Besuchs werden u.a. folgende Daten erfasst:</p>
            <ul>
                <li>die von Ihnen aufgerufenen Seiten, Ihr &bdquo;Klickpfad&ldquo;</li>
                <li>Erreichung von &bdquo;Website-Zielen&ldquo; (Conversions, z.B. Newsletter-Anmeldungen, Downloads, K&auml;ufe)</li>
                <li>Ihr Nutzerverhalten (beispielsweise Klicks, Verweildauer, Absprungraten)</li>
                <li>Ihr ungef&auml;hrer Standort (Region)</li>
                <li>Ihre IP-Adresse (in gek&uuml;rzter Form)</li>
                <li>technische Informationen zu Ihrem Browser und den von Ihnen genutzten Endger&auml;ten (z.B. Spracheinstellung, Bildschirmaufl&ouml;sung)</li>
                <li>Ihr Internetanbieter</li>
                <li>die Referrer-URL (&uuml;ber welche Website/ &uuml;ber welches Werbemittel Sie auf diese Website gekommen sind)</li>
            </ul>
            <br />
            <p><strong>Zwecke der Verarbeitung</strong><br />Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre (pseudonyme [NICHT BEI NUTZUNG VON USER-ID]) Nutzung der Website auszuwerten und um Reports &uuml;ber die Website-Aktivit&auml;ten zusammenzustellen. Die durch Google Analytics bereitgestellten Reports dienen der Analyse der Leistung unserer Website [OPTIONAL] und des Erfolgs unserer Marketing-Kampagnen.</p>
            <p><strong>Empf&auml;nger</strong><br />Empf&auml;nger der Daten ist</p>
            <ul>
                <li>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland</li>
            </ul>
            <p>als Auftragsverarbeiter. Hierf&uuml;r haben wir mit Google einen Auftragsverarbeitungsvertrag abgeschlossen. Die Google LLC mit Sitz in Kalifornien, USA, und ggf. US-amerikanische Beh&ouml;rden k&ouml;nnen auf die bei Google gespeicherten Daten zugreifen.</p>
            <p><strong>&Uuml;bermittlung in Drittstaaten</strong><br />Eine &Uuml;bermittlung von Daten in die USA kann nicht ausgeschlossen werden.</p>
            <p><strong>Speicherdauer</strong><br />Die von uns gesendeten und mit Cookies verkn&uuml;pften Daten werden nach 14 Monaten automatisch gel&ouml;scht. Die L&ouml;schung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
            <p>Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie<br />a. Ihre Einwilligung in das Setzen des Cookies nicht erteilen oder<br />b. das Browser-Add-on zur Deaktivierung von Google Analytics&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener norefferrer">HIER</a>&nbsp;herunterladen und installieren.</p>
            <p>Sie k&ouml;nnen die Speicherung von Cookies auch durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wenn Sie Ihren Browser so konfigurieren, dass alle Cookies abgelehnt werden, kann es jedoch zu Einschr&auml;nkung von Funktionalit&auml;ten auf dieser und anderen Websites kommen.</p>
            <p><strong>Rechtsgrundlage und Widerrufsm&ouml;glichkeit</strong><br />f&uuml;r diese Datenverarbeitung ist Ihre Einwilligung,&nbsp;<a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank" rel="noopener norefferrer">Art.6 Abs.1 S.1 lit.a DSGVO</a>. Sie k&ouml;nnen Ihre Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen, indem Sie die Cookie-Einstellungen [HIER LINK ZU DEN EINSTELLUNGSM&Ouml;GLICHKEITEN DES CONSENT-TOOLS SETZEN] aufrufen und dort Ihre Auswahl &auml;ndern.</p>
            <p>N&auml;here Informationen zu Nutzungsbedingungen von Google Analytics und zum Datenschutz bei Google finden Sie unter&nbsp;<a href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank" rel="noopener norefferrer">https://marketingplatform.google.com/about/analytics/terms/de/</a>&nbsp;und unter&nbsp;<a href="https://policies.google.com/?hl=de" target="_blank" rel="noopener norefferrer">https://policies.google.com/?hl=de</a>.</p>
        </div>
    )
}