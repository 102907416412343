import React, {useEffect, useRef, useState}               from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {useGlobalContext}    from "../context"

import "../components/Layout/index.css"
import "../components/SportgeländeUndStammvereine/verein.css"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import Beitrag              from "../components/Beiträge/Beitrag";
// import PlayerCardsList      from "../components/PlayerCard/PlayerCardsList";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';
import SocialMedia from "../components/Social Media/SocialMedia"
import BFV from "../components/Social Media/BFV"
import Archiv               from "../components/MainLayout/ArchivSlider/ArchivSlider"


export default function Home() {

    const {beiträge, images, filterFotos, archiv} = useGlobalContext();


    useEffect(()=>{
        window.scrollTo(0, 0);
    })


    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }



    return (
        <div>
            <Helmet>
                <title>Die Offizielle Webseite des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Die offizielle Vereins-Website des SV-Sendelbach-Steinbach. Der SVSS stellt für jede Altersklasse eine Mannschaft. Die Erste Herrenmannschaft spielt in der Kreisklasse 3 Würzburg."/>
            </Helmet>

            {/* Navigation */}
            {images
                ?<Navbar src={filteredFotos} pageName="Startseite" />
                :<Loading />
            }

            {/* Neueste Beiträge */}
            <Link to="/beiträge"><Überschrift überschrift="neueste Beiträge" /></Link>
                <div className="beiträge-wrapper">
                    {beiträge
                        ?
                        beiträge.map((beitrag, index) => {
                            if(index < 3){
                                return(
                                    <Beitrag key={beitrag.id} {...beitrag} />
                                )
                            } else {
                                return
                            }    
                        })
                        :
                        <Loading />
                    }
                </div>
            
            {/* Übersicht */}
            {/* <Link to="/herren"><Überschrift überschrift="Übersicht" /></Link>
            <BFV /> */}

            {/* Social Media */}
            {/* <Überschrift überschrift="Social Media" />
            <SocialMedia /> */}
            
            {/* Sportgelände */}
            <Link to="/stammvereine-sportgelände"><Überschrift überschrift="Sportgelände" /></Link>
                <div className="verein">
                    <div className="verein-wrapper">
                        <div className="verein-wrapper-inner">
                            <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.141872017626!2d9.602162215946928!3d50.008696026977034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2c623a180dda5%3A0xe90fb71e1d532eec!2sSportverein%20Steinbach!5e0!3m2!1sde!2sde!4v1615844118455!5m2!1sde!2sde" loading="lazy"></iframe>
                        </div>
                        <div className="verein-wrapper-inner">
                            <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2565.327669299664!2d9.599754815946376!3d49.98646592856843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2c87f79c08405%3A0xcfde6c9c5c1c85f2!2sSV%20Sendelbach%201919%20e.V.!5e0!3m2!1sde!2sde!4v1615843980936!5m2!1sde!2sde" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            {/* Fanshop */}
            {/* <Link to="/fanshop"><Überschrift überschrift="Fanshop" /></Link> */}

            <Überschrift überschrift="Archiv" />
            <h6 style={{width: "100%", textAlign: "center", padding: "10px"}}>Für jede Altersklasse gibt es zusätzlich jeweils noch ein eigenes Archiv, mit den Highlights der letzten Jahre.</h6>
            {archiv
                ?<Archiv archivName="Startseite" />
                :<Loading />
            }

            <Footer />
        </div>
    )
}
