import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
// import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import StammvereineUndSportgeländeListe      from "../components/SportgeländeUndStammvereine/StammvereineUndSportgeländeListe";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';


export default function Beiträge() {
    
    const {images, filterFotos} = useGlobalContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>SV Sendelbach-Steinbach e.V. Fusionsverein des SV Sendelbach e.V. und des SV Steinbach e.V.</title>
                <meta name="description" content="SV Sendelbach-Steinbach e.V. ist ein Fusionsverein des SV Sendelbach e.V. und des SV Steinbach e.V."/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Stammvereine" />
                :<Loading />
            }
            <StammvereineUndSportgeländeListe />
            <Footer />
        </div>
    )
}
