import React, {useEffect, useState}             from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import PlayerCardsList      from "../components/PlayerCard/PlayerCardsList";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';
import KontaktElement       from "../components/Kontakt/KontaktElement"
import TrainingElement from "../components/Trainingszeiten/Trainingszeiten"
import Archiv               from "../components/MainLayout/ArchivSlider/ArchivSlider"


export default function AlteHerren() {

    const {training, filterTraining, kontakt, filterKontakte, images, filterFotos} = useGlobalContext();
    
    //Trainingszeiten
    let filteredTraining;
    if(training){
        filteredTraining = filterTraining("Alte Herren");
    }

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Alte Herren", images).fotos
    }

					

    return (
        <div>
            <Helmet>
                <title>Die Alte Herren Mannschaft des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Die Alte Herren Mannschaft des SV Sendelbach-Steinbach"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Alte Herren" />
                :<Loading />
            }


            {/* Training */}
            {filteredTraining
                ?
                <div>
                    <Überschrift überschrift="Trainingszeiten" />
                    <div className="training wrapper">
                        {
                            <TrainingElement {...filteredTraining}/>
                        }
                    </div>
                </div>  
                :<div></div>
            }

            {/* Kontakt */}
            <Überschrift überschrift="Kontakt" />
            {kontakt
                ?<div className="kontakt wrapper">
                {   
                    filterKontakte("Alte Herren").kontakte.map((kontakt, index) => {
                        return <KontaktElement {...kontakt} key={index}/>
                    })
                }
                </div>
                : <Loading />
            }

            {/* Archiv */}
            <Überschrift überschrift="Archiv" />
            {kontakt
                ?<Archiv archivName="Alte Herren" />
                : <Loading />
            }
            
            <Footer />
        </div>
    )
}
