import React from 'react';
import {useGlobalContext}    from "../../context"
import Loading              from         '../MainLayout/Loading/Loading';

import { Link } from 'react-router-dom'
import "./beitrag.css"

export default function Beitrag({begegnung, datum, fotos, kategorie, text, titel, wettbewerb, id}) {
    

    const {images, filterFotos} = useGlobalContext();
    datum = new Date(datum);

    //falls keine Fotos beim Beitrag dabei sind, nutze Standard-Fotos
    if(images){
        let newKategorie = kategorie;

        //Ersatzfoto für Kategorie 1. Mannschaft und 2. Mannschaft = Herren Fotos
        if(kategorie === "1. Mannschaft" || kategorie === "2. Mannschaft" || kategorie === "Alte Herren" ){
            newKategorie = "Herren";
        }

        //Ersatzfoto für Kategorien "Weiteres, Alle, News"
        if(kategorie === "Alle" || kategorie === "News"){
            newKategorie = "Ersatzfoto";
        }

        //Fotos zurück geben
        if(filterFotos(newKategorie, images)){
            var filteredFotos = filterFotos(newKategorie, images).fotos;
        }

        //falls Kategorie != keine entsprechenden Fotos vorhanden
        if(!filteredFotos){
            filteredFotos = filterFotos("Startseite", images).fotos;
        }
    }

    text = text.content.map(item => {
        return item.content[0].value
    })

    text = text.join(" ").substring(0, 300);

    let slug = titel.replace(/\s+/g, '-').replace("/", '-').toLowerCase();


    return (
            <Link to={`/beiträge/${slug}`} className="beitrag-wrapper">
                <div className="image-wrapper">
                    {fotos
                        ?<img src={fotos[0].src} alt={fotos[0].title} title={fotos[0].description} />
                        :images
                            ?<img src={filteredFotos[0].src} />
                            :<Loading />
                    }
                </div>
                
                <div className="text-wrapper">
                    <h3 className="titel">{titel}</h3>
                    <div className="text">
                        {text} <b>...weiter lesen </b>
                    </div>
                    <br/>
                    <div className="datum">{datum.getDate()}. {datum.getMonth()+1}. {datum.getFullYear()}</div>
                    <div className="beitrag-info beitrag">
                        {begegnung&&
                            <div className="beitrag-info__inner">
                                <div className="beitrag-info__inner_ü">Begegnung: </div>
                                <div>{` ${begegnung}`}</div>
                            </div>  }

                        {wettbewerb&&
                        <div className="beitrag-info__inner">
                            <div className="beitrag-info__inner_ü">Wettbewerb: </div>
                            <div>{` ${wettbewerb}`}</div>
                        </div>  }

                        {kategorie&&
                        <div className="beitrag-info__inner">
                            <div className="beitrag-info__inner_ü">Kategorie: </div>
                            <div>{` ${kategorie}`}</div>
                        </div>  }
                    </div> 
                </div>
                </Link>
    )
}
