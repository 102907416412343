import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context";

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';
import KontaktElement from "../components/Kontakt/KontaktElement";
import TrainingElement from "../components/Trainingszeiten/Trainingszeiten"
import Archiv               from "../components/MainLayout/ArchivSlider/ArchivSlider"
import Trainersteckbriefe from '../components/Trainersteckbrief/Trainersteckbriefe';

export default function U5() {

    
    const {training, filterTraining, kontakt, filterKontakte, images, filterFotos, archiv, steckbriefe} = useGlobalContext();
    
    //Trainingszeiten
    let filteredTraining;
    if(training){
        filteredTraining = filterTraining("U5")
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    if(images){
        var filteredFotos = filterFotos("U5", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Die U5-Junioren-Mannschaft des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Die U5-Junioren-Mannschaft ist die jüngste Altersklasse der Jugendmannschaften des SV Sendelbach-Steinbach"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="U5" />
                :<Loading />
            }

            {/* Training */}
            {filteredTraining
                ?
                <div>
                    <Überschrift überschrift="Trainingszeiten" />
                    <div className="training wrapper">
                        {
                            <TrainingElement {...filteredTraining}/>
                        }
                    </div>
                </div>  
                :<div></div>
            }

            <Überschrift überschrift="Kontakt" />
            {steckbriefe
                ?<Trainersteckbriefe team="U5" />
                :<Loading/>
            }
            {kontakt
                ?<div className="kontakt wrapper">
                {
                    filterKontakte("U5").kontakte.map((kontakt, index) => {
                        return <KontaktElement {...kontakt} key={index}/>
                    })
                }
                </div>
                : <Loading />
            }

            {/* Archiv */}
            <Überschrift überschrift="Archiv" />
            {archiv
                ?<Archiv archivName="U5" />
                : <Loading />
            }

            <Footer />
        </div>
    )
}
