import React from 'react'
import "./Hamburger.css"

function Hamburger(props) {
    
    let isActive = props.isActive ? " is-active" : ""
    let leftOrRight = " " + props.leftOrRight

    return (
        <div>
            <div className={"hamburger hamburger--squeeze" + leftOrRight + isActive} onClick={props.handleClick}>
                <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                </div>
            </div>         
        </div>
    )
}

export default Hamburger
