import React from 'react'
import "./downloadButton.css"
import { AiOutlineDownload } from 'react-icons/ai';

export default function DownloadButton({titel, datei}) {
    return (
            <button className="downloads btn">
                <span className="überschrift">{titel}</span>
                <a href={datei} target="blank" download>
                    <AiOutlineDownload className="icon"/>
                </a>
            </button>
    )
}




