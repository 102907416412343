import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';
import Fanshop from '../components/Fanshop/Fanshop';


export default function JFG() {

    
    const {kontakt, filterKontakte, images, filterFotos} = useGlobalContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Der Fanshop des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Im Fanshop des SV Sendelbach-Steinbach bekommen sie die Aussattung, die man als Fan des SVSS braucht."/>
            </Helmet>
            
            {images
                ?<Navbar src={filteredFotos} pageName="Fanshop" />
                :<Loading />
            }
            
            <Überschrift überschrift="Fanshop" />
            {kontakt
                ?<div className="kontakt wrapper">
                    <Fanshop />
                </div>
                : <Loading />
            }

            <Footer />
        </div>
    )
}
