import React, {useState, useEffect} from "react";
import contentful from "./Contentful"
// import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import RichTextResponse from "./contentful-render-options"


const Context = React.createContext();

const ContextProvider = ({children}) => {

    const [images, setImages]   = useState();
    const [archiv, setArchiv]   = useState();
    const [beiträge, setBeiträge]   = useState();
    const [steckbriefe, setSteckbriefe] = useState();
    const [kontakt, setKontakt] = useState();
    const [training, setTraining] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getFotos();
        getArchiv();
        getBerichte();
        getSteckbriefe();
        getKontakt();
        getTraining();
    }, [])

    //Fotos
    function formatFotos(items){
        let tempFotos = items.map(item => {
            let id = item.sys.id;
            let fotos;
            if(item.fields.fotos){
                fotos = item.fields.fotos.map(foto => {
                    let fotoNeu = {src: foto.fields.file.url, title: foto.fields.title, description: foto.fields.description}
                    return fotoNeu;
                })
            }

            let images = {...item.fields, id, fotos};
            return images
        })
        return tempFotos;

    }

    async function getFotos() {
        setIsLoading(true);
        try {
            let response = await contentful.getEntries({
                content_type: "fotos",
                order: "-fields.titel"
            })
            const formatedFotos = formatFotos(response.items);
            setImages(formatedFotos);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    const filterFotos = (titel, fotosArray) => {
        let tempIndex;
        const navFotos = fotosArray.map((foto,index) => {
            if(foto.titel === titel){
                tempIndex = index
                return foto;
            } else {
                return foto;
            }
        })
        if(tempIndex){
            return navFotos[tempIndex]
        } else{
            return null;
        }
        
    }

    //Archiv
    function formatArchiv(items){
        let tempArchiv = items.map(item => {
            let id = item.sys.id;

            let kategorie = item.fields.kategorie;

            let archivName = item.fields.titel;

            let fotos;
            if(item.fields.fotos){
                fotos = item.fields.fotos.map(foto => {
                    let fotoNeu = {src: foto.fields.file.url, title: foto.fields.title, description: foto.fields.description}
                    return fotoNeu;
                })
            }

            let archiv = {...item.fields, id, fotos, kategorie, archivName};
            return archiv
        })
        return tempArchiv;

    }

    async function getArchiv() {
        try {
            let response = await contentful.getEntries({
                content_type: "archiv",
                order: "-fields.titel"
            })
            const formatedArchiv = formatArchiv(response.items);
            setArchiv(formatedArchiv);
        } catch (error) {
            console.log(error)
        }
    }

    const filterArchiv = (input, archivArray) => {
        let tempIndex;
        const tempArchiv = archivArray.map((archiv,index) => {
            if(archiv.archivName === input){
                tempIndex = index
                return archiv;
            } else {
                return archiv;
            }
        })
        return tempArchiv[tempIndex];
    }

    //Berichte
    function formatBerichte(items){
        let tempBerichte = items.map(item => {
            let id = item.sys.id;

            let links = RichTextResponse(item.fields.text)

            let text = item.fields.text;

            let autor = item.fields.autor;

            let fotos;
            if(item.fields.fotos){
                fotos = item.fields.fotos.map(foto => {
                    let fotoNeu = {src: foto.fields.file.url, title: foto.fields.title, description: foto.fields.description}
                    return fotoNeu;
                })
            }

            let instagram;
            if(item.fields.instagram){
                instagram = item.fields.instagram.content[0].content[0].value;
            }

            let beiträge = {...item.fields, id, fotos, text, instagram, links, autor};
            return beiträge
        })
        return tempBerichte;

    }

    async function getBerichte() {
        try {
            let response = await contentful.getEntries({
                content_type: "beitraege",
                order: "-fields.datum",
                limit: 1000
            })
            const formatedBeiträge = formatBerichte(response.items);
            setBeiträge(formatedBeiträge);
            console.log("Wir haben " + formatedBeiträge.length + " Beiträge!")
        } catch (error) {
            console.log(error)
        }
    }

    const filterBerichte = (input, berichteArray) => {
        let tempIndex;
        const tempBerichte = berichteArray.map((bericht,index) => {
            if(bericht.input === input){
                tempIndex = index
                return bericht;
            } else {
                return bericht;
            }
        })
        return tempBerichte[tempIndex]
    }

    //Trainersteckbriefe
    function formatSteckbriefe(items){
        let tempSteckbriefe = items.map(item => {
            let id = item.sys.id;

            let name = item.fields.name;

            let team = item.fields.team;

            let steckbrief = {src: item.fields.steckbrief.fields.file.url, title: item.fields.steckbrief.title, description: item.fields.steckbrief.description};

            let steckbriefe = {...item.fields, id, steckbrief, team, name};
            return steckbriefe
        })
        return tempSteckbriefe;

    }

    async function getSteckbriefe() {
        try {
            let response = await contentful.getEntries({
                content_type: "trainersteckbrief",
                order: "-fields.team"
            })
            const formatedSteckbriefe = formatSteckbriefe(response.items);
            setSteckbriefe(formatedSteckbriefe);
        } catch (error) {
            console.log(error)
        }
    }

    // const filterSteckbriefe = (input, steckbriefArray) => {
    //     let tempIndex;
    //     const tempSteckbrief = steckbriefArray.filter((steckbrief,index) => {
    //         if(steckbrief.team === input){
    //             tempIndex = index
    //             return steckbrief;
    //         } else {
    //             return steckbrief;
    //         }
    //     })
    //     return tempSteckbrief[tempIndex]
    // }

    const filterSteckbriefe = (input, steckbriefArray) => {
        const tempSteckbrief = steckbriefArray.filter((steckbrief,index) => {
            return steckbrief.team === input
        })
        console.log(tempSteckbrief)
        return tempSteckbrief;
    }

    //Kontakt
    function formatKontakt(items){
        let tempKontakt = items.map(item => {
            let id = item.sys.id;
            
            let kontakt = {...item.fields, id};
            return kontakt
        })
        return tempKontakt;

    }

    async function getKontakt() {
        try {
            let response = await contentful.getEntries({
                content_type: "kontakt",
            })
            const formatedKontakt = formatKontakt(response.items);
            setKontakt(formatedKontakt);
        } catch (error) {
            console.log(error)
        }
    }

    const filterKontakte = (input) => {
        let tempIndex;
        const tempKontakt = kontakt[0].kontakt.map((kontäkt,index) => {
            if(kontäkt.team === input){
                tempIndex = index;
                return kontäkt;
            } else {
                return kontäkt;
            }
        })

        if(tempIndex != null){
            return tempKontakt[tempIndex]
        } else{
            console.log("Kontakt nicht vorhanden")
        }
    }

    //Trainingszeiten
    function formatTraining(items){
        let tempTraining = items.map(item => {
            let id = item.sys.id;
            
            let training = {...item.fields, id};
            return training
        })
        return tempTraining;

    }

    async function getTraining() {
        try {
            let response = await contentful.getEntries({
                content_type: "training",
            })
            const formatedTraining = formatTraining(response.items);
            setTraining(formatedTraining);
        } catch (error) {
            console.log(error)
        }
    }

    const filterTraining = (input) => {
        let tempIndex;
        
        const tempTraining = training[0].trainingszeiten.map((train,index) => {
            
            if(train.team === input){
                tempIndex = index;
                return train;
            } else {
                return train;
            }
        })

        if(tempIndex != null){
            return tempTraining[tempIndex]
        } else{
            console.log("Training nicht vorhanden")
        }
    }

    return(
        <Context.Provider value={{
            images,
            filterFotos,
            archiv,
            filterArchiv,
            beiträge,
            filterBerichte,
            steckbriefe,
            filterSteckbriefe,
            kontakt,
            filterKontakte,
            training,
            filterTraining,
            isLoading,
        }}>
            {children}
        </Context.Provider>
    )
}

const useGlobalContext = () => {
    return React.useContext(Context)
}

export {ContextProvider, Context, useGlobalContext}









// function App() {

//     const [count, setCount] = useState(0);
//     const [images, setImages] = useState(src);
  
//     useEffect(() => {
//       const lastIndex = images.length -1;
//       if(count < 0)           { setCount(lastIndex)}
//       if(count > lastIndex)   { setCount(0)}
//     }, [count, images]);
  
//     useEffect(() => {
//      let slider = setInterval(() => {setCount(count+1)}, 2000)
//      return () => clearInterval(slider)
//     }, [count])
  
//     const checkActive = (number) => {
//       if(number === count){ return "activeSlide"}
//       if(count === 0 & number === images.length -1){ return "lastSlide"}
//       if(number === count-1 & number >= 0){ return "lastSlide"}
//       else{ return "nextSlide"}
//     }

//     setInterval(() => {
//         setCount(count+1)
//     }, 2000);
  
//     return (
//         <div>
//             {images.map ( (image, index) => {  
//             return (
//                 <div className={`navbar__image-wrapper ${checkActive(index)}`}>
//                     <img className="navbar__background-Image" src={image} alt=""/>
//                 </div>
//             )
//             })}
    
//             <button className="prev" onClick={() => setCount(count-1)}><FiChevronLeft /></button>
//             <button className="next" onClick={() => setCount(count+1)}><FiChevronRight /></button>
//         </div>
//       )
//   }