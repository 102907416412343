import React, {useState, useEffect} from 'react'
import "./darkModeButton.css"

const cDark = () => {
    if(window.localStorage.getItem("isDark")){
        if(window.localStorage.getItem("isDark") === "true"){
            return true;
        } else {
            return false;
        }

    } else {
        return false
    }
}

export default function DarkModeButton() {

    const [isDark, setIsDark] = useState(cDark);

    useEffect(()=>{
        toggleColor();
        window.localStorage.setItem("isDark", isDark)
    }, [isDark])

    function toggleColor(){
        if(!isDark){
            document.documentElement.style.setProperty(`--secondary-color`, "#ffffff");
            document.documentElement.style.setProperty(`--tertiary-color`, "#111111");
        } else{
            document.documentElement.style.setProperty(`--secondary-color`, "#111111");
            document.documentElement.style.setProperty(`--tertiary-color`, "#ffffff");
        }
    };

    return (
        <div onClick={() => setIsDark(!isDark)} className={`dark-mode-button ${isDark&&"dark"}`}>
            {isDark
                ? <div className="hell">
                    hell
                </div>
                : <div className="dunkel">
                    dark
                </div>
            }
        </div>
    )
}
