import React, {useState, useEffect} from 'react'
import contentful from "../../../Contentful"
import Sponsor from "./Sponsor"
import Loading from "../Loading/Loading"
import "./sponsoren.css"

export default function Sponsoren() {

    const [sponsoren, setSponsoren] = useState(true);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        getSponsoren();
    }, [])

    function formatSponsoren(items){
        let tempSponsoren = items.map(item => {
            let id = item.sys.id;
            let logo = item.fields.logo.fields.file.url;

            let sponsoren = {...item.fields, id, logo};
            return sponsoren
        })
        return tempSponsoren;

    }

    async function getSponsoren() {
        setLoading(true);
        try {
            let response = await contentful.getEntries({
                content_type: "sponsoren",
                order: "-fields.sponsor"
            })
            const formatedSponsoren = formatSponsoren(response.items);
            setSponsoren(formatedSponsoren);
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="sponsoren-wrapper">
            {loading
                ?<div style={{color: "var(--svss-blue)"}}></div>
                :sponsoren.map( sponsor=> {
                    return <Sponsor {...sponsor} key={sponsor.id} />
                })
            }    
            {/* {console.log(sponsoren)}   */}
        </div>
    )
}
