import React, {useEffect}                from 'react'
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"
import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Loading from '../components/MainLayout/Loading/Loading';


export default function Beiträge() {

    
    const {images, filterFotos} = useGlobalContext();

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Error 404</title>
                <meta name="description" content="Diese Seite konnte leider nicht gefunden werden"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Error 404" />
                :<Loading />
            }
        </div>
    )
}