import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import VorstandschaftList      from "../components/PlayerCard/VorstandschaftList";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';


export default function Vorstandschaft() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const {images, filterFotos} = useGlobalContext()

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Die Vorstandschaft des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Die Vorstandschaft SV Sendelbach-Steinbach"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Vorstandschaft" />
                :<Loading />
            }
            <VorstandschaftList />
            <Footer />
        </div>
    )
}
