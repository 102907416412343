import React, {useEffect, useState}             from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import PlayerCardsList      from "../components/PlayerCard/PlayerCardsList";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';
import KontaktElement       from "../components/Kontakt/KontaktElement";
import TrainingElement      from "../components/Trainingszeiten/Trainingszeiten";
import Archiv               from "../components/MainLayout/ArchivSlider/ArchivSlider"

export default function Herren() {

    const {training, filterTraining, kontakt, filterKontakte, images, filterFotos, archiv} = useGlobalContext();
    
    //Trainingszeiten
    let filteredTraining;
    if(training){
        filteredTraining = filterTraining("Herren")
    }

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Herren", images).fotos
    }
				

    return (
        <div>
            <Helmet>
                <title>Die beiden Herren Mannschaft des SV Sendelbach-Steinbach</title>
                <meta name="description" content="Die Erste Mannschaft des SV Sendelbach-Steinbach spielt in der Kreisseklasse 3 Würzburg. Die Zweite Mannschaft spielt in der A-Klasse 5 Würzburg"/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName="Herren" />
                :<Loading />
            }
            
            {/* <Überschrift überschrift="Liga" /> */}
            {/* {!loading
                && 
                <div>
                    <div id="bfv1615315163847">Laden...</div>
                    {BFVWidget.HTML5.zeigeMannschaftKomplett("016PI3OKI4000000VV0AG811VUDIC8D7", "bfv1615315163847", { height: "600", width: "350", selectedTab:BFVWidget.HTML5.mannschaftTabs.spiele, colorResults: "#272D61" , colorNav: "rgb(255,255,255)" , colorClubName : "#272D61" , backgroundNav: "#272D61"})}                
                </div> 
                
            } */}
           
           <PlayerCardsList />

            {/* Training */}
            {filteredTraining
                ?
                <div>
                    <Überschrift überschrift="Trainingszeiten" />
                    <div className="training wrapper">
                        {
                            <TrainingElement {...filteredTraining}/>
                        }
                    </div>
                </div>  
                :<div></div>
            }


            {/* Kontakt */}
            <Überschrift überschrift="Kontakt" />
            {kontakt
                ?<div className="kontakt wrapper">
                {   
                    
                    filterKontakte("1. Mannschaft").kontakte.map((kontakt, index) => {
                        if(kontakt.name === "Christoph Kolbe" || kontakt.name === "Michael Goldbach"){
                            return
                        } else {
                            return <KontaktElement {...kontakt} key={index}/>
                        }
                    })
                }
                {   
                    filterKontakte("2. Mannschaft").kontakte.map((kontakt, index) => { 
                        return <KontaktElement {...kontakt} key={index}/>
                    })
                }
                </div>
                : <Loading />
            }
            
            {/* Archiv */}
            <Überschrift überschrift="Archiv" />
            {archiv
                ?<Archiv archivName="Herren" />
                : <Loading />
            }

            <Footer />
        </div>
    )
}
