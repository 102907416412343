import React from 'react'
import { useGlobalContext } from '../../context';
import "./trainersteckbrief.css"


export default function TrainersteckbriefComponent({name, steckbrief, team}) {

    return (
        <div className="steckbrief-wrapper-inner">
            <img src={steckbrief.src} alt={steckbrief.title} title={steckbrief.description} />
        </div>
    )
}
