import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import {useGlobalContext} from "../../../context"

import "./Navbar.css"
import Hamburger from "./Hamburger/Hamburger"
import Navigation from "./Navigation/Navigation"
import Loading from '../Loading/Loading';

function Navbar({pageName, src}) {

    

//Hamburger
    const [clickLeft, setClickLeft] = useState(false)
    const [clickRight, setClickRight] = useState(false)
    const [clickSmall, setClickSmall] = useState(false);

    const handleClickLeft = () => setClickLeft(!clickLeft);
    const handleClickRight = () => setClickRight(!clickRight);
    const handleClickSmall = () => setClickSmall(!clickSmall);

    const [sliderOn, sliderOnSwitch] = useState(true);


//Slider
    const [count, setCount] = useState(0);
    const [images, setImages] = useState(src);

    useEffect(()=>{
        setImages(src);
        // console.log(src)
    }, [])

    useEffect(() => {
        const lastIndex = images.length -1;
        if(count < 0)           { setCount(lastIndex)}
        if(count > lastIndex)   { setCount(0)}
    }, [count, images]);
  
    
    useEffect(() => {
        if(images.length > 1 & sliderOn){
            let slider = setInterval(() => {setCount(count+1)}, 4000)
            return () => clearInterval(slider)
        }
    }, [count, sliderOn])
  
    const checkActive = (number) => {
      if(number === count){ return "activeSlide"}
      if(count === 0 & number === images.length -1){ return "lastSlide"}
      if(number === count-1 & number >= 0){ return "lastSlide"}
      if(number === count+1 & number <= images.length -1){ return "nextSlide"}
      else{ return "otherSlide"}
    }

    return (
        <div>
  {/* onMouseEnter={()=>sliderOnSwitch(false)} onMouseLeave={()=>sliderOnSwitch(true)} */}
            <div className="navbar" onClick={()=>sliderOnSwitch(state => !state)} >
                <div className="navbar__leiste"></div> 
                <Link to="/">
                    <div className="svss-logo-wrapper">
                        <img className="svss-logo" src="/Images/SvssLogo.png" alt="SVSS Logo"/>
                    </div>
                </Link>
                <Navigation clickLeft={clickLeft} clickRight={clickRight} clickSmall={clickSmall}/>
                <Hamburger leftOrRight="hamburger-left normal-window"    handleClick={handleClickLeft} isActive={clickLeft}/>
                <Hamburger leftOrRight="hamburger-right normal-window"   handleClick={handleClickRight} isActive={clickRight}/>
                <div className="page-name">{pageName}</div>
                
                    {src
                        ?
                            images.map ( (image, index) => {  
                                return (
                                    <div key={index} className={`slider-wrapper ${checkActive(index)}`} >
                                        <img className="slider-image" src={image.src} alt={image.title} title={image.description} />
                                        <img className="slider-image-background" src={image.src} style={{background: `center / cover no-repeat url(${image.src})`}}/>
                                        <div className="slider-image-title">{image.title}</div>
                                    </div>
                                )
                            })
                        :
                            <div className="nav-alt-img-wrapper">
                                <img className="nav-alt-img" src={"/Images/HerrenMannschaftsfoto.jpg"} alt="SVSS Mannschaftsfoto 2019"/>
                            </div>
                    }
                                
                    <button className="prev" onClick={() => images.length>1&&setCount(count-1)}><FiChevronLeft /></button>
                    <button className="next" onClick={() => images.length>1&&setCount(count+1)}><FiChevronRight /></button>    
            </div>
            <Hamburger leftOrRight="hamburger-left small-window"  handleClick={handleClickSmall} isActive={clickSmall}/>
        </div>
    )
}

export default Navbar
