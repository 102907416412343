import React, {useEffect, useState} from 'react';

import Loading from "../MainLayout/Loading/Loading"
import PlayerCard from "./PlayerCard.js"
import Überschrift from "../MainLayout/Überschrift/Überschrift"

import contentful from "../../Contentful"



export default function VorstandschaftList() {

    const [vorstandschaft, setVorstand]               = useState([]);
    const [loading, setLoading]                       = useState([true])

    useEffect(() => {
        setLoading(true)
        getVorstand();
        setLoading(false)
    }, [])

    function formatVorstand(items){
        let tempVorstand = items.map(item => {
            let id = item.sys.id;
            let foto = item.fields.foto.fields.file.url;

            let vorstand = {...item.fields, id, foto}
            return vorstand
        })
        return tempVorstand;
    }

    async function getVorstand() {
        try {
            let response = await contentful.getEntries({
                content_type: "vorstandschaft",
                order: "sys.createdAt"
            })
            const formatedVorstand = formatVorstand(response.items);
            setVorstand(formatedVorstand)
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Überschrift überschrift="Vorstand" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "Vorstand"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Kassier" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "Kassier"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Schriftführer" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "Schriftführer"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="sportliche Leitung" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "sportliche Leitung"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Jugendleitung" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "Jugendleitung U5-U11"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="JFG-Beauftragter" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "JFG-Beauftragter"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
            <Überschrift überschrift="Beisitzer" />
            {loading
                ?<Loading></Loading>
                :
                <div className="player-grid">
                    {vorstandschaft.map((person) => {
                        if(person.funktion === "Beisitzer"){
                            return (<PlayerCard {...person} key={person.id} />)
                        }
                    })}
                </div>
            }
        </div>
    )
}
