import React, {useEffect, useState} from 'react';

import "./stammvereineUndSportgeländeListe.css"
import Loading from "../MainLayout/Loading/Loading"
import Überschrift from "../MainLayout/Überschrift/Überschrift"
import Verein from "./Verein"

import contentful from "../../Contentful"



export default function StammvereineUndSportgeländeListe() {

    const [content, setContent]   = useState([]);
    const [loading, setLoading]   = useState([true])
    const steinbach = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.141872017626!2d9.602162215946928!3d50.008696026977034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2c623a180dda5%3A0xe90fb71e1d532eec!2sSportverein%20Steinbach!5e0!3m2!1sde!2sde!4v1615844118455!5m2!1sde!2sde`
    const sendelbach = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2565.327669299664!2d9.599754815946376!3d49.98646592856843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2c87f79c08405%3A0xcfde6c9c5c1c85f2!2sSV%20Sendelbach%201919%20e.V.!5e0!3m2!1sde!2sde!4v1615843980936!5m2!1sde!2sde"`

    useEffect(() => {
        setLoading(true)
        getContent();
        setLoading(false)
    }, [])


    function formatContent(items){
        let tempDownloads = items.map(item => {
            let id = item.sys.id;
            let logo = item.fields.logo.fields.file.url;
            let sportgelaende = item.fields.sportgelaende.map((foto) => {
               return foto.fields.file.url
            });
            let text = item.fields.text.content[0].content[0].value;

            let content = {...item.fields, id, logo, sportgelaende ,text}
            return content
        })
        return tempDownloads;
    }

    async function getContent() {
        try {
            let response = await contentful.getEntries({
                content_type: "stammvereine-sportgelaende",
                order: "-fields.verein"
            })
            const formatedContent = formatContent(response.items);
            setContent(formatedContent)
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {loading
                ?<Loading></Loading>
                :
                <div className="content-wrapper">
                    {content.map((item) => {
                        if(item.verein === "SV Steinbach e.V."){
                            return (
                                <Verein {...item} key={item.id} ort={steinbach}/>
                            )
                        } 
                        if(item.verein === "SV Sendelbach e.V."){
                            return ( 
                                <Verein {...item} key={item.id} ort={sendelbach}/>
                            )
                        } else {
                            console.log("Fehler bei Vereinen")
                        }                          
                    })}
                </div>
            }
        </div>
    )
}