import React, {useEffect}                from 'react'
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet-async"

import {useGlobalContext}    from "../context"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading              from '../components/MainLayout/Loading/Loading';
import KontaktElement from "../components/Kontakt/KontaktElement"
import JfgInfo  from "../components/JFG/JFG"
import Archiv               from "../components/MainLayout/ArchivSlider/ArchivSlider"


export default function JFG() {

    
    const {kontakt, filterKontakte, images, filterFotos} = useGlobalContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("U19-U13", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Die Jugendmannschaften U13-U19 als Teil der JFG Spessarttor</title>
                <meta name="description" content="Die Jugendmannschaften U13-U19 des SV Sendelbach-Steinbach sind Teil der JFG Spessarttor"/>
            </Helmet>
            
            {images
                ?<Navbar src={filteredFotos} pageName="JFG Spessarttor" />
                :<Loading />
            }

            <JfgInfo />
            
            <Überschrift überschrift="Kontakt" />
            {kontakt
                ?<div className="kontakt wrapper">
                {
                    filterKontakte("JFG").kontakte.map((kontakt, index) => {
                        return <KontaktElement {...kontakt} key={index}/>
                    })
                }
                </div>
                : <Loading />
            }

            {/* Archiv */}
            <Überschrift überschrift="Archiv" />
            {kontakt
                ?<Archiv archivName="U19-U13" />
                : <Loading />
            }

            <Footer />
        </div>
    )
}
