import React from 'react'
import "./kontakt.css"

export default function KontaktElement({funktion, name, email, telefon}) {

    return (
        <div className="person">
            <h4 className="funktion">{funktion}</h4>
            <h3 className="name">{name}</h3>
            <div className="email">{email}</div>
            <div className="telefon">{telefon}</div>
        </div>
    )
}
