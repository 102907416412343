import React, {useEffect}                from 'react'
import {useGlobalContext}    from "../context"
import {Helmet} from "react-helmet-async"

import Navbar               from "../components/MainLayout/Navbar/Navbar";
// import Überschrift          from '../components/MainLayout/Überschrift/Überschrift';
import DownloadList         from "../components/Downloads/DownloadList";
import Footer               from "../components/MainLayout/Footer/Footer";
import Loading from '../components/MainLayout/Loading/Loading';


export default function Downloads() {

    
    const {images, filterFotos} = useGlobalContext();

    //Scroll to top initially
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(images){
        var filteredFotos = filterFotos("Startseite", images).fotos
    }

    return (
        <div>
            <Helmet>
                <title>Alle Downloads der SV Sendelbach-Steinbach Homepage</title>
                <meta name="description" content="Alle Downloads sind hier zu finden. Eckenheft/Eckle/Fanzeitschrift. Anmelde- und Kontaktformulare."/>
            </Helmet>

            {images
                ?<Navbar src={filteredFotos} pageName={`Downloads`} />
                :<Loading />
            }
            <DownloadList />
            <Footer />
        </div>
    )
}
