import React, {useEffect, useState} from 'react';

import Loading from "../MainLayout/Loading/Loading"
import Überschrift from "../MainLayout/Überschrift/Überschrift"
import KontaktElement from "./KontaktElement"
import {kontaktData} from "./kontaktData"

import contentful from "../../Contentful"
import "./kontakt.css"
import { useGlobalContext } from '../../context';



export default function KontaktListe() {

    const {kontakt, filterKontakte} = useGlobalContext();
    
    return (
        <div>
            {kontakt
                ?  
                <div>
                    <Überschrift überschrift="Alte Herren" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("Alte Herren").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                    <Überschrift überschrift="1. Mannschaft" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("1. Mannschaft").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="2.Mannschaft" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("2. Mannschaft").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="JFG Spessarttor" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("JFG").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="U11" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("U11").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="U9" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("U9").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="U7" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("U7").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="U5" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("U5").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                <Überschrift überschrift="Website" />
                    <div className="kontakt wrapper">
                        {
                            filterKontakte("Website").kontakte.map((kontakt, index) => {
                                return <KontaktElement {...kontakt} key={index}/>
                            })
                        }
                    </div>
                </div>
   
                :<Loading />
            }
        </div>
    )
}
