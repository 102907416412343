import React from 'react'
import {Link} from "react-router-dom"
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai'
import DarkModeButton from '../DarkModeButton/DarkModeButton'
import Sponsoren from "./Sponsoren"

import "./Footer.css"

function Footer() {

    let year = new Date().getFullYear()

    return (
        <div className="footer">
            <Link to="/">
                <div className="svss-logo-wrapper">
                    <img className="svss-logo" src="/Images/SvssLogo.png" alt="Svss"/>
                </div>
            </Link>
            <div className="social-media">
                <div className="social-media__text überschrift">SVSS auf Social Media</div>
                <div className="social-media__icons">
                    <a href="https://www.facebook.com/SVSS2008/" target="blank">
                        <AiFillFacebook style={{width: "50px", height: "50px", color: "var(--white)"}} />
                    </a> 
                    <a href="https://www.instagram.com/sendelbachsteinbach/" target="blank">
                        <AiFillInstagram style={{width: "50px", height: "50px", color: "var(--white)"}} />
                    </a> 
                </div>
            </div>
            <div className="partner">
                <div className="partner__text überschrift">Partner</div>
                <Sponsoren />
            </div>
            <div className="nav-links nav-links--main"> 
                <div className="nav-links__inner">
                    <div className="überschrift">Teams</div>
                        <div className="nav-links__inner__inner">
                            <div className="nav-wrapper">
                                <Link to="/alte-herren" className="nav-text">Alte Herren</Link>
                                <Link to="/herren" className="nav-text">Herren</Link>
                                <Link to="/jfg-spessarttor" className="nav-text">JFG</Link>
                                <Link to="/u11" className="nav-text">U11</Link>
                                
                            </div>
                            <div className="nav-wrapper">
                                <Link to="/u9" className="nav-text">U9</Link>
                                <Link to="/u7" className="nav-text">U7</Link>
                                <Link to="/u5" className="nav-text">U5</Link>
                                <Link to="/vorstandschaft" className="nav-text">Vorstandschaft</Link>
                            </div>
                        </div>
                </div>
                    
                <div className="nav-links__inner">
                    <div className="überschrift">Weiteres</div> 
                    <div className="nav-links__inner__inner">
                        <div className="nav-wrapper">
                            <Link to="/beiträge" className="nav-text">Beiträge</Link>
                            <Link to="/fanshop" className="nav-text">Fanshop</Link>
                            <Link to="/chronik" className="nav-text">Chronik</Link>
                            <Link to="/stammvereine-sportgelände" className="nav-text">Stammvereine</Link>                    
                        </div> 
                        <div className="nav-wrapper">  
                            <Link to="/downloads" className="nav-text">Downloads</Link> 
                            <Link to="/kontakt" className="nav-text">Kontakt</Link>
                            <Link to="/impressum" className="nav-text">Impressum</Link>
                            <Link to="/datenschutz" className="nav-text">Datenschutz</Link>
                        </div> 
                    </div>
                </div>     
            </div>
            <div className="nav-wrapper--sonstige">          
                </div>
            <div className="impressum-text">
                <div>©{year} SV Sendelbach-Steinabch</div>
                <div>
                    Website erstellt:  
                    <a href="https://www.alina-webseiten.de/" target="blank" className="nav-text">ALINA WEBSEITEN</a>
                </div>
            </div>
            <DarkModeButton />
        </div>
    )
}

export default Footer
