import React, {useEffect, useState, useRef} from 'react';
import {useGlobalContext}    from "../../context"
import "./suche.css"



import {BiDownArrow} from "react-icons/bi"

export default function Suche({suchen, kategorien}) {
    
    const [input, setInput] = useState("");

    function onSubmit(e) {
        e.preventDefault();
        setInput(e.currentTarget.value)
    }

    useEffect(()=> {
        suchen(input)
    }, [input])

    
    return(
        <div className="suche-wrapper"> 
            <form action="" >
                <input type="text" name="input" placeholder="Beitrag suchen..." onChange={onSubmit}/>
                {/* <button className="btn-1" type="submit" value="Submit">suchen</button> */}
            </form>
        </div>
    )
  
}
