import React from 'react'
import { useGlobalContext } from '../../context';

import TrainersteckbriefComponent from "./TrainersteckbriefComponent";
import "./trainersteckbrief.css"


export default function Trainersteckbriefe({team}) {

    const {steckbriefe, filterSteckbriefe} = useGlobalContext();

    let filteredSteckbriefe;
    if(steckbriefe){
        filteredSteckbriefe = filterSteckbriefe(team, steckbriefe);
    }

    return (
        filteredSteckbriefe[0]
        ?
        <div className="steckbrief">
            <div className="steckbrief-wrapper">
                {
                    filteredSteckbriefe.length > 1
                    ?
                        filteredSteckbriefe.map((steckbrief, index) => {
                            return <TrainersteckbriefComponent key={index} {...steckbrief}/>
                        })   
                    :
                        <TrainersteckbriefComponent {...filteredSteckbriefe[0]}/>                     
                }
            </div>
        </div>
        :
        <div></div>      
    )
}