import React, {Link} from 'react'
import "./sponsoren.css"

export default function Sponsor({logo, sponsor, website, id}) {
    return (
            <a target="blank" href={website} className="sponsoren-logo-wrapper">
                <img  src={logo} alt={sponsor} className="sponsoren-logo"/>
            </a>
    )
}
