import React, {useEffect, useState} from 'react';

import "./jfg.css"
import Loading from "../MainLayout/Loading/Loading"
import Überschrift from "../MainLayout/Überschrift/Überschrift"

import contentful from "../../Contentful"
import { useGlobalContext } from '../../context';



export default function JfgInfo() {

    return (
        <div>
            <Überschrift überschrift="Info" />
            <div className="jfg wrapper">
                <h3 className="text">
                    Der SV Sendelbach/Steinbach und fünf weitere Sportvereine aus dem Raum Lohr haben am 4. Mai 2012 mit der Fußball-Junioren-Fördergemeinschaft (JFG) einen neuen, eigenständigen Jugendverein ins Leben gerufen. Die Stammvereine im Einzelnen sind
                </h3>
                <div className="logos wrapper">
                    <div className="logo-wrapper">
                        <img src="/Images/SvssLogo.png" alt="SV Sendelbach-Steinbach" className="logo" />
                        <div className="verein">SV Sendelbach-Steinbach</div>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/Images/Vereine/DJK-Wombach.png" alt="DJK Wombach" className="logo" />
                        <div className="verein">DJK Wombach</div>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/Images/Vereine/TSV-Lohr.jpg" alt="TSV Lohr" className="logo" />
                        <div className="verein">TSV Lohr</div>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/Images/Vereine/TSV-Sackenbach.png" alt="TSV Sackenbach" className="logo" />
                        <div className="verein">TSV Sackenbach</div>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/Images/Vereine/FSV-Neustadt-Erlach.png" alt="FSV Neutstadt/Erlach" className="logo" />
                        <div className="verein">FSV Neutstadt/Erlach</div>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/Images/Vereine/SV-Rodenbach.png" alt="SV Rodenbach" className="logo" />
                        <div className="verein">SV Rodenbach</div>
                    </div>
                </div>
            </div>
            <Überschrift überschrift="Website" />
            <div className="jfg wrapper">
                <h3 className="text">
                    <a style={{fontFamily: "Franklin Gothic Medium", color: "black"}} href="https://jfg-spessarttor.de/" target='_blank'>HIER KLICKEN</a>, um zur Homepage der JFG Spessarttor zu wechseln!
                </h3> 
            </div>

            {/* <Überschrift überschrift="U19" />
            <Überschrift überschrift="U17" />
            <Überschrift überschrift="U15" />
            <Überschrift überschrift="U13" /> */}
        </div>
    )
}