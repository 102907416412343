import React from 'react'
import {Link} from "react-router-dom"
import "./Navigation.css"

export default function Navigation({clickLeft, clickRight, clickSmall}) {

    return (
        <nav>
            <div className={`nav nav-left ${clickLeft?"":"isnt-active"}`}>
                <ul className="nav-text-wrapper">
                    <li>
                        <Link to="/alte-herren" className="nav-text">Alte Herren</Link>
                    </li>
                    <li>
                        <Link to="/herren" className="nav-text">Herren</Link>
                    </li>
                    <li>
                        <Link to="/jfg-spessarttor" className="nav-text">JFG</Link>
                    </li>
                    <li>
                        <Link to="/u11" className="nav-text">U11</Link>
                    </li>
                    <li>
                        <Link to="/u9" className="nav-text">U9</Link>
                    </li>
                    <li>
                        <Link to="/u7" className="nav-text">U7</Link>
                    </li>
                    <li>
                        <Link to="/u5" className="nav-text">U5</Link>
                    </li>
                    <li>
                        <Link to="/vorstandschaft" className="nav-text">Vorstandschaft</Link>
                    </li>
                </ul>
            </div>
            <div className={`nav nav-right ${clickRight?"":"isnt-active"}`}>
                <ul className="nav-text-wrapper">
                    <li>
                        <Link to="/beiträge" className="nav-text">Beiträge</Link>
                    </li>
                    <li>
                        {/* <Link to={"https://sv-sendelbach-steinbach.fan12.de/"} target="_blank" className="nav-text">Fanshop</Link> */}
                        <a className="nav-text" target='_blank' rel='noopener noreferrer' href="https://sv-sendelbach-steinbach.fan12.de/">Fanshop</a>
                    </li>
                    <li>
                        <Link to="/chronik" className="nav-text">Chronik</Link>
                    </li>
                    <li>
                        <Link to="/stammvereine-sportgelände" className="nav-text">Stammvereine</Link>
                    </li>
                    <li>
                        <Link to="/downloads" className="nav-text">Downloads</Link>
                    </li>
                    <li>
                        <Link to="/kontakt" className="nav-text">Kontakt</Link>
                    </li>
                    <li>
                        
                    </li>   
                    <li>
                        
                    </li>  
                    <li>
                        
                    </li>       
                </ul>
            </div>
            <div className={`nav nav-central ${clickSmall?"":"isnt-active"}`}>
                <ul className="nav-text-wrapper">
                    <li><h2 className="nav-text überschrift">Teams</h2></li>
                    <li>
                        <Link to="/alte-herren" className="nav-text">Alte Herren</Link>
                    </li>
                    <li>
                        <Link to="/herren" className="nav-text">Herren</Link>
                    </li>
                    <li>
                        <Link to="/jfg-spessarttor" className="nav-text">JFG</Link>
                    </li>
                    <li>
                        <Link to="/u11" className="nav-text">U11</Link>
                    </li>
                    <li>
                        <Link to="/u9" className="nav-text">U9</Link>
                    </li>
                    <li>
                        <Link to="/u7" className="nav-text">U7</Link>
                    </li>
                    <li>
                        <Link to="/u5" className="nav-text">U5</Link>
                    </li>
                    <li>
                        <Link to="/vorstandschaft" className="nav-text">Vorstandschaft</Link>
                    </li>
                    <br/>

                    <li><h2 className="nav-text überschrift">Weiteres</h2></li>
                    <li>
                        <Link to="/beiträge" className="nav-text">Beiträge</Link>
                    </li>
                    <li>
                        <Link to="/fanshop" className="nav-text">Fanshop</Link>
                    </li>
                    <li>
                        <Link to="/chronik" className="nav-text">Chronik</Link>
                    </li>
                    <li>
                        <Link to="/stammvereine-sportgelände" className="nav-text">Stammvereine/<br/>Sportgelände</Link>
                    </li>
                    <li>
                        <Link to="/downloads" className="nav-text">Downloads</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
